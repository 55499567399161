<template>
  <div class="container-stats">
    <div class="container-statistics">
      <ul id="breadcrumb" class="breadcrumb ProximaNovaRegular mx-4">
        <!--<li>
          <router-link to="/solutions/in/platform/models">
            <span>Model</span>
          </router-link>
        </li>-->
        <li>
          <router-link to="/solutions/dp/platform/">
            <span>KPI</span>
          </router-link>
        </li>
        <li>
          <router-link to="/solutions/dp/platform/allstats">
            <span>Model Result</span>
          </router-link>
        </li>
        <li class="ProximaNovaBold">Attribution</li>
      </ul>
    </div>
    <div class="d-flex flex-row" style="height: 40%">
      <div class="w-100 pt-4">
        <w-white-dropdown :options="brandDropdown" :placeHolder="'Select'" :selectedOption="selectedBrand"
          :labelText="'Brand'" style="float: right; width: 350px;" class="mr-3" @input="selectBrand($event)">
        </w-white-dropdown>
      </div>

      <div class="pt-4">
        <w-white-dropdown :options="mediaDropdown" :placeHolder="'Select'" :selectedOption="selectedMedia"
          :labelText="dropdownLabel" class="mr-3" @input="selectMedia($event)"></w-white-dropdown>
      </div>
    </div>

    <div class="container-line-chart w-100">
      <div class="title">Attribution</div>
      <div class="col-md-12 col-lg-12 col-sm-12 my-4">
        <div class="card pb-5 pb-lg-5 h-100">
          <div class="">
            <ColumnChart :chartData="allMobelBarChart" />
          </div>
        </div>
      </div>
      <div class="text-right my-5 w-100 col-md-12 col-lg-12 col-sm-12 my-4">
        <w-button :buttonText="'Ensemble'" class="my-5" :buttonLoader="'normal'" @buttonClicked="nextToTheInsights">
        </w-button>
      </div>
      <StatusModal ref="solutionModal" @progressDone="doneProgress" />
    </div>
  </div>
</template>
<script>
import Button from "@/components/Profile/Button.vue";
import ColumnChart from "@/components/Chart/ColumnChart.vue";
import StatusModal from "@/components/Modal/SolutionModal.vue";
import WhiteDropdown from "@/widgets/WhiteDropdown.vue";

// import { AimoUnileverServices } from "@/services/AimoUnileverServices.js";
// const aimoUnileverServices = new AimoUnileverServices();

import { AimoDatapoemServices } from "@/services/AimoDatapoemServices.js";
const aimoDatapoemService = new AimoDatapoemServices();

export default {
  name: "AllStats",
  components: {
    "w-button": Button,
    ColumnChart,
    StatusModal,
    "w-white-dropdown": WhiteDropdown,
  },
  data() {
    return {
      dropdownLabel: "",
      clientId: sessionStorage.getItem("subId"),
      marketType: localStorage.getItem("selectedKPISource"),
      selectedBrandKPI: localStorage.getItem("selectedBrandKPI"),

      overallDetailsData: [
        { label: "Actual Sales", value: "1131.24 Cr" },
        { label: "Predicted Sales", value: "1091.8 Cr" },
        { label: "Error %", value: "3.48%" },
      ],
      brandDropdown: [

      ],
      mediaDropdown: [

      ],
      selectedBrand: {
        text: localStorage.getItem("selectedBrandKPI"),
        id: localStorage.getItem("selectedBrandKPI"),
      },
      selectedMedia: {
        text: localStorage.getItem("selectedMediaKPI"),
        id: localStorage.getItem("selectedMediaKPI"),
      },
      allMobelBarChart: {
        data: [],
        title: {
          text: "Attribution by Channel for All Models(%)",
          align: "left",
          margin: 50,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        color: [
          "#faacfe",
          "#67e8ff",
          "#ffdd7c",
          "#b5a9ff",
          "#ffa2a2",
          "#d81b60",
          "#fb8c00",
        ],
        legend: {
          align: "left",
          verticalAlign: "bottom",
          layout: "horizontal",
          itemMarginTop: 30,
          itemStyle: {
            color: "#8394b4",
            fontSize: "16px",
            fontFamily: "ProximaNovaRegular",
          },
        },
        xAxis: {
          categories: ["2017", "2018", "2019"],
          crosshair: {
            enabled: true,
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: {
          visible: true,
          title: {
            text: "",
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          lineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "14px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        plotOptions: {
          column: {
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
            pointPadding: 0.3,
            dataLabels: {
              enabled: true,
            },
          },
        },
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
        },
      },
      selectedMarket: {},
    };
  },
  methods: {
    saveToLocal() {
      // if (this.selectedMedia.id && this.selectedBrand.id !== null) {
      localStorage.setItem(
        "selectedKPISource",
        `${this.selectedBrand.id}_${this.selectedMedia.id}`
      );
      this.marketType = `${this.selectedBrand.id}_${this.selectedMedia.id}`;
      localStorage.setItem("selectedBrandKPI", this.selectedBrand.text);

      this.getOverallAttribution();

      // localStorage.setItem("selectedBrandKPI", this.selectedKPISource.text)
      // }
    },
    selectMedia(e) {
      this.selectedMedia = e;
      this.saveToLocal();
    },
    selectBrand(e) {
      this.selectedBrand = e;
      this.saveToLocal();
    },
    doneProgress() {
      this.$router.push("/solutions/dp/platform/acid-test");
    },
    nextToTheInsights() {
      // this.$refs.solutionModal.open();
      this.saveToLocal()
      this.$router.push("/solutions/dp/platform/acid-test");
    },
    getOverallAttribution() {
      if (this.selectedBrand.text !== "undefined" && this.selectedMedia.text !== undefined) {
        aimoDatapoemService
          .getChartData(this.selectedBrand.text, this.selectedMedia.text, "overallattribution", this.clientId)

          .then((res) => {
            var res_data = res.response;
            this.allMobelBarChart.data = [];
            this.allMobelBarChart.xAxis.categories = [];
            for (var i = 0; i < res_data.length; i++) {
              this.allMobelBarChart.xAxis.categories.push(res_data[i].name);
              for (var j = 0; j < res_data[i].value.length; j++) {
                var dataFind = this.allMobelBarChart.data.find(
                  (x) => x.name === res_data[i].value[j].name
                );
                if (dataFind !== undefined) {
                  dataFind.data.push(res_data[i].value[j].value);
                } else {
                  this.allMobelBarChart.data.push({
                    name: res_data[i].value[j].name,
                    data: [res_data[i].value[j].value],
                  });
                }
              }
            }
          })
          .catch(() => { });
      }
    },
    getBrands(cliendId) {
      this.brandDropdown = []
      this.mediaDropdown = []
      aimoDatapoemService.getClientBrandDetails(cliendId).then((res) => {
        let dropdown_labels = Object.keys(res[0])
        this.dropdownLabel = dropdown_labels[1]

        let brand = res[0].Brand
        let kpi = res[0][this.dropdownLabel]

        for (var brands = 0; brands < brand.length; brands++) {
          this.brandDropdown.push({ id: brand[brands], text: brand[brands] })
        }

        for (var kpis = 0; kpis < kpi.length; kpis++) {
          this.mediaDropdown.push({ id: kpi[kpis], text: kpi[kpis] })
        }

        if (this.selectedBrand.text === "undefined") {
          this.selectedBrand = this.brandDropdown[0]
        } else if (this.selectedMedia.text === "undefined") {
          this.selectedMedia = this.mediaDropdown[0]
        }

        this.saveToLocal()

        // this.selectedBrand = this.brandDropdown[0]
        // this.selectedMedia = this.mediaDropdown[0]
      }).catch((err) => {
        alert(err)
      })
    },
  },
  created() {
    // this.selectedMarket = JSON.parse(
    //   sessionStorage.getItem("solutionsKpiMarket")
    // );
    // this.getOverallAttribution();

    this.getBrands(this.clientId)
  },
};
</script>

<style lang="css" scoped>
.container-stats {
  width: 93%;
  margin: 71px auto 0 auto;
}

.container-statistics {
  padding-top: 1px;
}

.title {
  font-family: ProximaNovaBold;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #313846;
  margin-top: 48px;
  margin-bottom: 32px;
}

.wrapper-statistics {
  box-shadow: 0px 0px 16px -13px rgb(0 0 0);
  border-radius: 4px;
  min-height: 126px;
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  padding: 0 2rem;
}

.card-heading {
  margin: 20px 0px;
  font-family: ProximaNovaBold;
  font-size: 20px;
  color: #222a37;
}

.card {
  box-shadow: 0 0px 8px 0 #c9ced5;
  background-color: #fff;
}

.item {
  display: flex;
  width: 20%;
  position: relative;
}

.perf-card-wrapper {
  margin: 0px 24px;
}

.perf-card {
  width: 277px;
  height: 97px;
  box-shadow: 0 0 8px 0 #c9ced5;
  display: flex;
  align-items: center;
  padding-left: 21px;
  margin-top: 44px;
}

.item:not(:last-child):before {
  content: "";
  position: absolute;
  height: 40px;
  width: 1px;
  background: #e8edf5;
  right: 20px;
  top: 8px;
}

.item-img {
  background: #67e8ff;
  border-radius: 50%;
  padding: 2rem;
  height: 56px;
  width: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.item-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 24px;
}

.item-text__title {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8394b4;
  padding-bottom: 1rem;
}

.item-text__subtitle {
  font-family: ProximaNovaBold;
  font-size: 28px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #313846;
}

.btn-effect {
  width: 190px;
  height: 60px;
  font-family: ProximaNovaRegular;
  background-color: #050505;
  color: #f3f3f3;
  border: none;
  text-align: left;
  font-size: 16px;
  padding-left: 20px;
}

.btn-effect i {
  font-size: 14px;
  float: right;
  margin-top: 7px;
  margin-right: 10px;
}
</style>
